import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const TitleContext = createContext()

export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('Self check-in')
  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  )
}
TitleProvider.propTypes = {
  children: PropTypes.node,
}
TitleProvider.defaultProps = {
  children: null,
}

export const TitleConsumer = TitleContext.Consumer

export default TitleContext
