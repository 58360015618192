import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useParams } from 'react-router-dom'
import {
  Typography, Card, CardMedia, CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import qr from 'qr-image'

import Loading from '../components/Loading'
import activityIdQuery from '../graphql/queries/activityId'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'hidden',
  },
  header: {
    alignSelf: 'flex-start',
  },
  card: {
    width: '100%',
  },
  logoWrapper: {
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  logo: {
    maxWidth: '100%',
    height: 120,
  },
  photoWrapper: {
    height: 160,
    textAlign: 'center',
  },
  photo: {
    height: 160,
  },
  content: {
    textAlign: 'center',
    width: '100%',
    paddingBottom: '8px !important',
  },
  row: {
    width: 160,
    borderRadius: spacing(1),
    borderColor: palette.secondary.main,
    borderStyle: 'solid',
    padding: spacing(1, 2),
    margin: '0 auto',
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  qrcode: {
    height: 140,
  },
  items: {
    display: 'flex',
    justifyContent: 'center',
  },
  shirtBox: {
    width: '40%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shirtType: {
    maxHeight: 120,
  },
  shirtSize: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  productBox: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  product: {
    maxWidth: '100%',
    maxHeight: 100,
  },
  line: {
    width: '100%',
  },
  option: {
    marginTop: spacing(2)
  },
}))

const EslipPage = (props) => {
  const { event } = props
  const classes = useStyles()
  const { activityId } = useParams()
  const { loading, data: { activityId: activity } = {} } = useQuery(activityIdQuery, { variables: { _id: activityId }, fetchPolicy: 'network-only' })
  const qrcode = useMemo(() => {
    if (activity && activity.participant) {
      return qr.imageSync(`https://runner.thai.run/BS21-2019/${activity.participant.bib.padStart(5, '0')}`, { type: 'png', parse_url: true })
    }
    return null
  }, [activity])
  if (loading) {
    return (
      <Loading />
    )
  }
  if (!event || !activity) {
    return (
      <Redirect to="/notfound" />
    )
  }
  const { participant } = activity
  const shirt = event.shirts.reduce((prev, cur) => participant.shirt && (cur.type === participant.shirt.type || cur.slug === participant.shirt.type ? cur : prev), null)
  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h5" component="h2" className={classes.header}>e-Pass</Typography>
      <Card className={classes.card}>
        <CardMedia className={classes.logoWrapper}>
          <img className={classes.logo} src={event.logo} alt={event.name} />
        </CardMedia>
        <CardMedia className={classes.photoWrapper}>
          <img className={classes.photo} src={activity.photo || participant.photo} alt="Participant" />
        </CardMedia>
        <CardContent className={classes.content}>
          <Typography variant="h5" align="center">{participant.firstname} {participant.lastname}</Typography>
          <Typography variant="h5" align="center">{participant.ageCategory}</Typography>
          {/* <Typography variant="h5">{participant.status}</Typography> */}
          <img className={classes.qrcode} src={`data:image/png;base64,${qrcode.toString('base64')}`} alt="QR Code" />
          <div className={classes.row}>
            <Typography variant="h4">{participant.row}</Typography>
          </div>
          <Typography variant="h4">BiB no. {participant.bib}</Typography>
          <div className={classes.items}>
            <div className={classes.shirtBox}>
              {shirt ? (
                <img className={classes.shirtType} src={shirt.image} alt={shirt.type} />
              ) : null}
              {participant.shirt ? (
                <Typography variant="h4" className={classes.shirtSize}>{participant.shirt.size}</Typography>
              ) : null}
            </div>
            {participant.options && participant.options.additionalProduct ? (
              <div className={classes.productBox}>
                <img className={classes.product} src="https://cdn.nativesrc.com/thairun/bs21-2019/ud.png" alt="UD" />
              </div>
            ) : null}
          </div>
          {participant.options && participant.options.vipShirt ? (
            <Typography className={classes.option} variant="subtitle2">{participant.options.vipShirt} แขนสีน้ำเงิน</Typography>
          ) : null}
          {participant.options && participant.options.firstFull ? (
            <Typography className={classes.option} variant="h5">First Full</Typography>
          ) : null}
          {participant.options.additionalProduct ? (
            <Typography variant="h5" align="left">ท่านมีสินค้า Pre-Order</Typography>
          ) : null}
          <hr className={classes.line} />
          {/* {participant.options && participant.options.additionalProduct ? (
            <Typography variant="h6">คุณมีรายการสั่งซื้อสินค้าเพิ่มเติม</Typography>
          ) : null} */}
          <Typography variant="body1">{moment(activity.createdAt).format('DD MMM YYYY, HH:mm:ss')}</Typography>
        </CardContent>
      </Card>
    </div>
  )
}
EslipPage.propTypes = {
  event: PropTypes.shape().isRequired,
}

export default EslipPage
