import React, { useContext, useEffect } from 'react'
import {
  Switch, Route, Redirect, useParams,
} from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import TitleContext from '../contexts/TitleContext'
import Loading from '../components/Loading'
import eventIdQuery from '../graphql/queries/eventId'

import InstructionPage from './InstructionPage'
import FaceIDPage from './FaceIDPage'
import EPassPage from './EPassPage'
import NotFoundPage from './NotFoundPage'

const EventPage = () => {
  const { eventId } = useParams()
  const { setTitle } = useContext(TitleContext)
  const { loading, data } = useQuery(eventIdQuery, { variables: { _id: eventId } })
  useEffect(() => {
    if (!loading && data) {
      const { eventId: event } = data
      setTitle(event.name)
    }
  }, [loading, data, setTitle])
  if (loading) {
    return (
      <Loading />
    )
  }
  const { eventId: event } = data
  if (!event) {
    return (
      <Redirect to="/notfound" />
    )
  }
  return (
    <Switch>
      <Route exact path="/:eventId">
        <InstructionPage event={event} />
      </Route>
      <Route exact path="/:eventId/faceid">
        <FaceIDPage event={event} />
      </Route>
      <Route exact path="/:eventId/epass/:activityId">
        <EPassPage event={event} />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default EventPage
