import React, {
  useCallback, useEffect, useRef, Fragment,
} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  Typography, Card, CardMedia, CardContent, CardActions, TextField, InputAdornment, Button, Fab,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search as SearchIcon } from '@material-ui/icons'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import Webcam from 'react-webcam'

import { useMultiState, useInterval, usePrevious } from '../hooks'
import {
  loadModels, fetchImage, getFullFaceDescription, createFaceMatcher, bufferToImage,
} from '../face'
import participantQuery from '../graphql/queries/participant'
import checkinMutation from '../graphql/mutations/checkin'
import Loading from '../components/Loading'
import SnackbarContentWrapper from '../components/SnackbarContent'

const RECOGNITION_TIMEOUT = 30000
const videoConstraints = {
  width: 320,
  height: 240,
  facingMode: 'user',
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  form: {
    width: '100%',
  },
  card: {
    width: '100%',
  },
  logo: {
    maxWidth: '100%',
  },
  photo: {
    height: 0,
    paddingTop: '66.67%', // 4:3
  },
  webcam: {
    display: 'flex',
    justifyContent: 'center',
  },
  frame: {
    width: '10%',
  },
  extendedIcon: {
    marginRight: spacing(1),
  },
  greeting: {
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
    padding: spacing(2),
  },
}))

const FaceIDPage = (props) => {
  const { event } = props
  const classes = useStyles()
  const history = useHistory()
  const webcamRef = useRef(null)
  const [state, setState] = useMultiState({
    search: '',
    participant: null,
    faceMatcher: null,
    loading: false,
    frames: [],
    error: null,
    timeoutId: null,
  })
  const {
    search,
    participant,
    faceMatcher,
    loading,
    frames,
    error,
    timeoutId,
  } = state
  const prevState = usePrevious({ faceMatcher, frames })
  const client = useApolloClient()
  const [checkin] = useMutation(checkinMutation)
  useEffect(() => {
    const init = async () => {
      await loadModels()
    }
    init()
  }, [])
  const handleCheckin = useCallback(
    async () => {
      const { _id: participantId } = participant
      const bestPhoto = frames.reduce((prev, cur) => {
        if (cur.face.distance < prev.distance) {
          return {
            distance: cur.face.distance,
            photo: cur.image,
            index: cur.key,
          }
        }
        return prev
      }, { distance: 1, photo: null, index: -1 })
      const descriptors = frames.map(({ face: { descriptor } }) => Array.from(descriptor))
      try {
        const response = await fetch(process.env.REACT_APP_UPLOAD_SERVER, {
          method: 'POST',
          body: JSON.stringify({
            photo: bestPhoto.photo,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        const result = await response.json()
        const { data } = await checkin({
          variables: {
            participantId, status: 'selfcheckin', descriptors, photo: result.url,
          },
        })
        if (data && data.checkin) {
          history.push(`/${event._id}/epass/${data.checkin.recordId}`)
        }
      } catch (err) {
        console.error(err)
      }
    },
    [event, history, checkin, participant, frames]
  )
  const handleSearch = useCallback(
    async (e) => {
      e.preventDefault()
      setState({
        participant: null,
        faceMatcher: null,
        loading: true,
        frames: [],
        error: null,
      })
      try {
        const { data } = await client.query({ query: participantQuery, variables: { eventId: event && event._id, search }, fetchPolicy: 'network-only' })
        const newState = { matcher: null, error: 'Can not check-in, Please contact staff!' }
        if (data && data.participant) {
          newState.participant = data.participant
          if (data.participant.photo) {
            try {
              const img = await fetchImage(data.participant.photo)
              const detections = await getFullFaceDescription(img)
              if (detections.length > 0) {
                newState.faceMatcher = createFaceMatcher(data.participant.bib, detections.map(detection => detection.descriptor))
                newState.error = null
              }
            } catch (err) {
              console.error(err)
            }
          }
        } else {
          newState.error = `Bib no ${search} not found`
        }
        setState(newState)
      } catch (err) {
        console.error(err)
      } finally {
        setState({ loading: false })
      }
    },
    [event, search, client, setState]
  )
  const capture = useCallback(
    async () => {
      if (webcamRef.current) {
        const image = webcamRef.current.getScreenshot()
        if (image && faceMatcher) {
          const blob = new Blob([Buffer.from(image.replace(new RegExp(/data:image\/jpeg;base64,/,), ''), 'base64')])
          const img = await bufferToImage(blob)
          const detections = await getFullFaceDescription(img)
          const recognition = detections.map((detection) => {
            const faceMatch = faceMatcher.findBestMatch(detection.descriptor)
            return {
              ...detection,
              label: faceMatch.label,
              distance: faceMatch.distance,
            }
          })
          const [face] = recognition.filter(recog => recog.label !== 'unknown')
          if (face) {
            setState((prev) => {
              if (prev.frames.length < 10) {
                return { frames: [...prev.frames, { key: prev.frames.length, face, image }] }
              }
              return prev
            })
          }
        }
      }
    },
    [webcamRef, faceMatcher, setState]
  )
  useEffect(() => {
    if (frames.length === 10) {
      handleCheckin()
    }
  }, [frames, handleCheckin])
  useEffect(() => {
    if ((faceMatcher && !prevState.faceMatcher) || (prevState && prevState.frames.length < frames.length)) {
      clearTimeout(timeoutId)
      const id = setTimeout(() => {
        setState({ error: 'Recognition timeout, Please contact staff!' })
      }, RECOGNITION_TIMEOUT)
      setState({ timeoutId: id })
    }
    return () => clearTimeout(timeoutId)
  }, [frames, faceMatcher, timeoutId, prevState, setState])
  useInterval(() => {
    if (participant && frames.length < 10) {
      capture()
    }
  }, 200)
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={handleSearch} className={classes.form}>
          <TextField
            fullWidth
            label="Search"
            placeholder="Enter bib number"
            variant="filled"
            style={{ margin: 0 }}
            value={search}
            onChange={e => setState({ search: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Search"
                    onClick={handleSearch}
                    disabled={search === ''}
                  >
                    <SearchIcon className={classes.extendedIcon} />
                  Search
                  </Fab>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {error ? (
          <SnackbarContentWrapper
            variant="error"
            message={error}
            action={undefined}
          />
        ) : null}
        {participant ? (
          <Fragment>
            {!faceMatcher ? (
              <CardMedia
                className={classes.photo}
                image={participant.photo || event.logo}
                style={(!participant.photo ? { backgroundSize: 'contain', backgroundColor: '#fff' } : {})}
              />
            ) : (
              <CardMedia className={classes.webcam}>
                <Webcam
                  audio={false}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </CardMedia>
            )}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                {`${participant.firstname} ${participant.lastname} (${participant.bib})`}
              </Typography>
              <Typography variant="h6" color="textSecondary" component="h4" align="center">
                {participant.ageCategory}
              </Typography>
            </CardContent>
            {faceMatcher && frames.length < 10 ? (
              <CardActions>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={frames.length < 10}
                >
                Recognizing ... {frames.length * 10}%
                </Button>
              </CardActions>
            ) : null}
            <CardMedia>
              {frames.map(frame => (
                <img key={frame.key} className={classes.frame} src={frame.image} alt="frame" />
              ))}
            </CardMedia>
          </Fragment>
        ) : (
          <CardMedia className={classes.greeting}>
            <img className={classes.logo} src={event.logo} alt={event.name} />
            {loading && (<Loading />)}
          </CardMedia>
        )}
      </Card>
    </div>
  )
}
FaceIDPage.propTypes = {
  event: PropTypes.shape().isRequired,
}

export default FaceIDPage
