import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 40,
  },
  progress: {
    margin: theme.spacing(2),
  },
}))

const Loading = (props) => {
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <CircularProgress disableShrink className={classes.progress} size={40} color="secondary" />
    </div>
  )
}

export default Loading
