import gql from 'graphql-tag'

export default gql`
query ($_id: MongoID!) {
  eventId (_id: $_id) {
    _id
    name
    organizer {
      name
    }
    logo
    shirts {
      slug
      type
      image
    }
    options {
      search {
        idcard
        qrcode
      }
      face {
        idcard
        upload
      }
      print
    }
    steps
  }
}
`
