import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography, List } from '@material-ui/core'
import { defaultConfig, Layout } from 'bearright-mui-layout'

import ListMenuItem from './components/ListMenuItem'
import Title from './components/Title'
import Footer from './components/Footer'
import NotFoundPage from './pages/NotFoundPage'
import InstructionPage from './pages/InstructionPage'
import EventPage from './pages/EventPage'

const App = () => (
  <Layout
    config={defaultConfig}
    header={(
      <Title />
    )}
    logo={(
      <Typography variant="h6" color="inherit" noWrap>Self check-in</Typography>
    )}
    menus={(
      <List dense>
        <ListMenuItem exact path="/" title="Instruction" />
      </List>
    )}
    footer={(
      <Footer poweredBy="Thairun" copyright="Copyright © 2019 Bear Right Technology Co.,Ltd." />
    )}
  >
    <Switch>
      <Route exact path="/" component={InstructionPage} />
      <Route exact path="/notfound" component={NotFoundPage} />
      <Route path="/:eventId" component={EventPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </Layout>
)

export default App
