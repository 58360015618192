import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  Typography, Button, Paper, MobileStepper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@material-ui/icons'

import TitleContext from '../contexts/TitleContext'

const tutorialSteps = [
  {
    label: 'Scan QR Code',
    imgPath: '/images/instruction-01.png',
  },
  {
    label: 'Enter bib number',
    imgPath: '/images/instruction-02.png',
  },
  {
    label: 'Verify FaceID',
    imgPath: '/images/instruction-03.png',
  },
  {
    label: 'Get e-Pass',
    imgPath: '/images/instruction-04.png',
  },
]

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: spacing(2),
    backgroundColor: palette.background.default,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}))

const InstructionPage = (props) => {
  const { event } = props
  const classes = useStyles()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(event ? 1 : 0)
  const { setTitle } = useContext(TitleContext)
  useEffect(() => {
    if (event) {
      setTitle(event.name)
    } else {
      setTitle('Instruction')
    }
  }, [event, setTitle])
  const maxSteps = tutorialSteps.length
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h5" component="h2">
        Self check-in instruction
      </Typography>
      <Paper square elevation={0} className={classes.header}>
        <Typography>Step {activeStep + 1} - {tutorialSteps[activeStep].label}</Typography>
      </Paper>
      <img
        className={classes.img}
        src={tutorialSteps[activeStep].imgPath}
        alt={tutorialSteps[activeStep].label}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={activeStep === maxSteps - 1 ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push(`/${event._id}/faceid`)}
            disabled={!event}
          >
            Check-in
            <KeyboardArrowRightIcon />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRightIcon />
          </Button>
        )}
        backButton={(
          <Button
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeftIcon />
            Back
          </Button>
        )}
      />
    </div>
  )
}
InstructionPage.propTypes = {
  event: PropTypes.shape(),
}
InstructionPage.defaultProps = {
  event: null,
}

export default InstructionPage
